import { actions as sessionActions } from '../../ducks/session';
import { actions as nodeActions } from '../../ducks/resource-nodes';
import { actions as edgeActions } from '../../ducks/resource-edges';

import Client from '../../../wrapped-cube-client';

import { createLoginMiddleware } from './login-middleware';
import { createRetrieveMiddleware } from './retrieve-middleware';
import { createMutateMiddleware } from './mutate-middleware';
import { createCreateMiddleware } from './create-middleware';
import {
  createContractSubmitMiddleware,
  actions as contractActions
} from './contract-submit-middleware/contract-submit-middleware';

export interface ResourceLike {
  id: string;
}

/**
 *  This middleware listen for actions from the
 *  resource-nodes and resource-edges "ducks"
 *
 *  resource-nodes stores all the details of a resource
 *  resource-edges stores the relationsship
 *  metadata between resources
 *
 *  when a retrieve action is detected, we map the payload
 *  to a wrapped-cube-clien call
 *
 *  responses are stored in a normalized way in one of above ducks
 *
 *  we also set request status for the actions
 *  via the request-status duck
 *
 *  TODO:
 *  - detect if a request is already in flight
 *  to prevent duplicate calls
 *  - handle MUTATIONS
 *
 *
 */

export const cubeClientMiddleware = ({ getState, dispatch }) => {
  const initialState = getState();
  if (initialState.session && initialState.session.account) {
    Client.auth.setWorkspaceId(initialState.session.workspace);
  }

  const loginMiddleware = createLoginMiddleware({ getState, dispatch });
  const retrieveMiddleware = createRetrieveMiddleware({ getState, dispatch });
  const mutateMiddleware = createMutateMiddleware({ getState, dispatch });
  const createMiddleware = createCreateMiddleware({ getState, dispatch });
  const contractSubmitMiddleware = createContractSubmitMiddleware({
    getState,
    dispatch
  });

  return (next) => (action) => {
    const { meta, type } = action;

    if (!meta?.apiClient && !meta?.contractSubmit) {
      return next(action);
    } else {
      switch (type) {
        // case sessionActions.SIGNUP:
        case sessionActions.SET_CURRENT_ACCOUNT:
        case sessionActions.LOGIN:
        case sessionActions.LOGIN_SSO:
        case sessionActions.LOGIN_MAGIC_LINK:
        case sessionActions.TOKEN_LOGIN:
        case sessionActions.REGISTER_ACCOUNT:
        case sessionActions.LOGOUT:
        case sessionActions.VERIFY_AUTH:
        case sessionActions.RESET:
        case sessionActions.REQUEST_TOKEN_RESET_PASSWORD:
        case sessionActions.RESET_PASSWORD:
        case sessionActions.SET_SHARE_PASSWORD:
        case sessionActions.CONFIRM_EMAIL:
        case sessionActions.CHANGE_EMAIL:
        case sessionActions.ACTIVATE_MAGIC_LINK:
        case sessionActions.MAGIC_LINK_ACTIVATED:
        case sessionActions.MAGIC_LINK_FAILED:
        case sessionActions.REQUEST_SHARE_ACCESS:
        case sessionActions.TWOFA_LOGIN:
        case sessionActions.ENROLL_TOTP:
        case sessionActions.ENROLL_TOTP_FINISH:
        case sessionActions.REGEN_RECOVERY_CODE:
          return loginMiddleware(next)(action);

        case nodeActions.RETRIEVE_RESOURCE:
        case edgeActions.RETRIEVE_RESOURCE_EDGES:
        case edgeActions.RETRIEVE_MORE_RESOURCE_EDGES:
        case edgeActions.RETRIEVE_ALL_RESOURCE_EDGES:
          return retrieveMiddleware(next)(action);

        case nodeActions.MUTATE_RESOURCE:
        case edgeActions.MUTATE_RESOURCE_EDGE:
        case edgeActions.MUTATE_MANY_RESOURCE_EDGES:
        case edgeActions.UGLY_MOVE_ITEMS:
        case edgeActions.UGLY_COPY_ITEMS:
        case nodeActions.DELETE_RESOURCE:
        case nodeActions.MUTATE_MANY_RESOURCES:
        case nodeActions.DELETE_MANY_RESOURCES:
          return mutateMiddleware(next)(action);

        case nodeActions.CREATE_RESOURCE:
        case nodeActions.CREATE_MANY:
        case nodeActions.CREATE_RESOURCE_BY_ANCESTOR:
        case nodeActions.CREATE_MANY_BY_ANCESTOR:
          return createMiddleware(next)(action);

        case contractActions.CONTRACT_SUBMIT:
          return contractSubmitMiddleware(next)(action);

        default:
          break;
      }
      return next(action);
    }
  };
};
