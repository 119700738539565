// import * as React from 'react';
// import { Theme, makeStyles, createStyles } from '@material-ui/core';
// import Tooltip from '@material-ui/core/Tooltip';
// import UseTruncateText from '../helpers/hooks/UseTruncateText';
// import { useClassName } from '../utils/useClassName';
// import { compareProps } from '../utils/compareProps';

export * from '@cube3/cubicle/src/core/utility/Truncate';
import Truncate from '@cube3/cubicle/src/core/utility/Truncate';
export default Truncate;

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       fontFamily: theme.typography.fontFamily,
//       wordBreak: 'break-all'
//     },
//     customWidth: {
//       maxWidth: 'none'
//     },
//     canvasStyle: {
//       display: 'none'
//     }
//   })
// );

// interface InterfaceTruncateProps {
//   str: string;
//   classes?: { root?: string; customWidth?: string };
//   fontsLoaded?: boolean;
//   placement?:
//     | 'bottom'
//     | 'left'
//     | 'right'
//     | 'top'
//     | 'bottom-end'
//     | 'bottom-start'
//     | 'left-end'
//     | 'left-start';
//   toolTip?: boolean;
//   containerStyles?: string;
//   widthLimit?: number;
//   lines?: number;
//   suffixComponent?: JSX.Element;
//   contentStyles?: string;
// }

// const fontsLoaded = true; // NOTE: we use a preloader now

// const arrayToString = (arr: string[]) =>
//   arr.reduce((acc, str) => acc + str, '');

// export const TruncatedText = React.memo<
//   React.PropsWithChildren<InterfaceTruncateProps>
// >((props) => {
//   const localClasses = useStyles();
//   const {
//     str,
//     placement,
//     toolTip = true,
//     containerStyles,
//     contentStyles = '',
//     widthLimit,
//     lines = 1,
//     classes,
//     suffixComponent
//   } = props;

//   const suffixComponentRef = React.useRef<HTMLSpanElement>(null);

//   const { containerRef, stringsArray } = UseTruncateText({
//     str,
//     fontsLoaded,
//     widthLimit,
//     lines,
//     suffixComponentRef
//   });

//   const classOverrides = React.useRef({ tooltip: localClasses.customWidth });

//   return (
//     <div
//       ref={containerRef}
//       className={useClassName(
//         localClasses.root,
//         classes?.root,
//         containerStyles
//       )}
//     >
//       <Tooltip
//         title={str ? str : ''}
//         placement={placement}
//         disableFocusListener={true}
//         disableTouchListener={true}
//         disableHoverListener={!toolTip || arrayToString(stringsArray) === str}
//         classes={classOverrides.current}
//       >
//         <div
//           style={{
//             display: 'flex',
//             flexDirection: 'column'
//           }}
//         >
//           {lines === 1 && !stringsArray.length ? (
//             <span data-cy="truncateSpan" className={contentStyles}>
//               {str}
//               {suffixComponent && (
//                 <span
//                   ref={suffixComponentRef}
//                   style={{ display: 'inline-block', flexShrink: 0 }}
//                 >
//                   {suffixComponent}
//                 </span>
//               )}
//             </span>
//           ) : (
//             stringsArray.map((txt, idx) => (
//               <span
//                 key={idx}
//                 data-cy="truncateSpan"
//                 data-pw={str}
//                 style={{
//                   wordBreak: stringsArray.length === 1 ? 'break-word' : 'unset',
//                   display: 'flex',
//                   alignItems: 'center'
//                 }}
//               >
//                 {txt}
//                 {idx === stringsArray.length - 1
//                   ? suffixComponent && (
//                       <span
//                         ref={suffixComponentRef}
//                         style={{ display: 'inline-block', flexShrink: 0 }}
//                       >
//                         {suffixComponent}
//                       </span>
//                     )
//                   : null}
//               </span>
//             ))
//           )}
//         </div>
//       </Tooltip>
//     </div>
//   );
// }, compareProps(['classes']));

// export default TruncatedText;
