const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d');

/* method to get the total width in pixels of a string using the provided font settings  */
export const getWidthOfText = (
  fullStr: string,
  font: string,
  letterSpacing: string
) => {
  ctx.font = font;

  // calculate manually because safari and jest don't understand
  const ls = calculateLetterSpacing(letterSpacing, fullStr, font);

  return Math.ceil(ctx.measureText(fullStr).width) + ls;
};

const calculateLetterSpacing = (
  letterSpacing: string,
  fullStr: string,
  font: string
) => {
  switch (true) {
    case !letterSpacing:
      return 0;

    case !!letterSpacing?.match('px'):
      return parseFloat(letterSpacing) * fullStr.length;

    case !!letterSpacing?.match('rem'):
      return (
        parseFloat(getComputedStyle(document.documentElement).fontSize) *
        parseFloat(letterSpacing) *
        fullStr.length
      );

    case !!letterSpacing?.match('em'):
      return (
        parseFloat(font.split(' ')[2]) *
        parseFloat(letterSpacing) *
        fullStr.length
      );

    default:
      return 0;
  }
};
