import React, { useMemo } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeDateString } from '@cube3/common/utils/makeDateString';

export const useDateString = ({
  valid,
  date,
  dateObj,
  fullForm,
  now,
  relativeTime,
  noTime,
  shortStyle
}) =>
  useMemo(
    () =>
      valid
        ? makeDateString({
            date,
            dateObj,
            fullForm,
            now,
            relativeTime,
            noTime,
            shortStyle
          })
        : undefined,
    [date, dateObj, fullForm, now, relativeTime, noTime, valid, shortStyle]
  );
export type TooltipPlacement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

interface Properties {
  /** Css classes injected in the `span` component */
  className?: string;
  /** The date to display, given in `Date` or `string` (ISO or RFC format) */
  date: string | Date | number;
  /** The relative date, defaults to `new Date()` (now) */
  now?: Date | number;
  /** Display in full form or short form, defaults to `false` */
  fullForm?: boolean;
  /** Default `Tooltip` placement options for when tooltip attribute is set to `true`. Defaults to `bottom-start` */
  placement?: TooltipPlacement;
  /** Display a tooltip when hovered? Defaults to `true` */
  toolTip?: boolean;
  /** Displays relative time like: in a month, defaults to `false` */
  relativeTime?: boolean;
  /** Defaults to false */
  noTime?: boolean;
  label?: string;
  /**Its displays time with a shorter format ex hr instead of hour*/
  shortStyle?: boolean;
}

export const Time = React.memo(
  ({
    date = new Date(),
    fullForm = false,
    toolTip = true,
    placement,
    now,
    relativeTime = false,
    noTime = false,
    className = '',
    label = undefined,
    shortStyle = false
  }: Properties) => {
    // try to convert the date string into epoch time.

    const dateObj =
      date && Object.getPrototypeOf(date) === Date ? date : new Date(date || 0);
    const valid = !Number.isNaN(dateObj.valueOf());

    const formatted = useDateString({
      valid,
      date,
      dateObj,
      fullForm: fullForm || noTime,
      now,
      relativeTime,
      noTime,
      shortStyle
    });

    // check if the time is epoch (number)
    if (!valid) {
      return <span> Date unavailable </span>;
    }

    const { dateString, fullFormDate } = formatted;

    return (
      <Tooltip
        title={label ? `${label} ${fullFormDate}` : fullFormDate}
        placement={placement ? placement : 'bottom-start'}
        disableFocusListener={true}
        disableTouchListener={true}
        disableHoverListener={!toolTip}
      >
        <span className={className}>{dateString}</span>
      </Tooltip>
    );
  }
);

export default Time;
