import { flip, offset, Placement } from '@floating-ui/dom';
import { FloatingPortal } from '@floating-ui/react';
import { Unstable_Popup as Popup } from '@mui/base/Unstable_Popup';
import React, { PropsWithChildren } from 'react';
import { SizeVariants } from '../../../theme/themes';
import { makeCSS } from '../../../utils/makeCSS';
import { useClassName } from '../../../utils/useClassName';
import { Panel } from '../../atoms/Panel';

/**
 * The ContextMenu is used to display and group together the different ContextMenuList components.
 * It is created by using the Panel compoment.
 */
export interface ContextMenuProps extends PropsWithChildren {
  children;
  /**The width of the contextMenu */
  width?: number | string;
  anchor?: HTMLElement;
  offset?: number;
}

const useCSS = makeCSS(
  ({ theme, width = 64 }: ContextMenuProps & { theme }) => {
    // const unit = parseInt(theme.spacing['1']);
    return {
      root: {
        borderRadius: theme.borderRadius['2xl'],

        boxShadow:
          '0px 16px 80px 0px rgba(0, 0, 0, 0.20), 0px 4px 4px 0px rgba(0, 0, 0, 0.20);'
      },
      margins: {
        '> div': {
          marginBottom: 4
        },
        '>*:last-child': {
          marginBottom: 0
        }
      },
      width: {
        width: typeof width === 'string' ? width : theme.spacing[width]
      }
    };
  }
);

export function ContextMenu(
  props: ContextMenuProps & { placement?: Placement }
) {
  const { children, anchor } = props;

  const classes = useCSS(props);

  const Container = anchor ? FloatingWrapper : React.Fragment;

  return (
    <Container {...props} anchor={anchor}>
      <div className={useClassName(classes.root, classes.width)}>
        <Panel
          className={`${classes.margins}`}
          cornerRadius={SizeVariants['2xl']}
          padding={2}
          backgroundColor={(theme) =>
            `linear-gradient(0deg, ${theme.color.overlay['02']} 0%, ${theme.color.overlay['02']} 100%), ${theme.color.background.neutral}`
          }
          borderColor={(theme) => theme.color.line['03']}
        >
          {children}
        </Panel>
      </div>
    </Container>
  );
}

export interface FloatingWrapperProps extends PropsWithChildren {
  anchor: HTMLElement;
  placement?: Placement;
  offset?: number;
}
const FloatingWrapper = (props: FloatingWrapperProps) => {
  const { anchor, placement, children } = props;
  return (
    <FloatingPortal>
      <Popup
        open={true}
        placement={placement || 'left'}
        disablePortal
        strategy="fixed"
        middleware={[
          offset(props.offset === undefined ? 8 : props.offset),
          flip()
        ]}
        anchor={anchor}
      >
        {children}
      </Popup>
    </FloatingPortal>
  );
};
