import { deprecate } from '../../utils/deprecate';

//
// const SIGNUP = 'cube3-client/session/SIGNUP';
// const SIGNUP_SUCCESS = 'cube3-client/session/SIGNUP_SUCCESS';
// const SIGNUP_FAILED = 'cube3-client/session/SIGNUP_FAILED';
const LOGIN = 'cube3-client/session/LOGIN';
const TOKEN_LOGIN = 'cube3-client/session/TOKEN_LOGIN';
const REGISTER_ACCOUNT = 'cube3-client/session/REGISTER_ACCOUNT';
const LOGIN_SUCCESS = 'cube3-client/session/LOGIN_SUCCESS';
const LOGIN_FAILED = 'cube3-client/session/LOGIN_FAILED';
const LOGIN_SSO = 'cube3-client/session/LOGIN_SSO';
const LOGIN_MAGIC_LINK = 'cube3-client/session/LOGIN_MAGIC_LINK';
const LOGOUT = 'cube3-client/session/LOGOUT';
const RESET = 'cube3-client/session/RESET';
const SET_CURRENT_ACCOUNT = 'cube3-client/session/SET_CURRENT_ACCOUNT';
const SET_CURRENT_WORKSPACE = 'cube3-client/session/SET_CURRENT_WORKSPACE';
const SET_CURRENT_SHARELINK = 'cube3-client/session/SET_CURRENT_SHARELINK';
const REQUEST_TOKEN_RESET_PASSWORD =
  'cube3-client/session/REQUEST_TOKEN_RESET_PASSWORD';
const RESET_PASSWORD = 'cube3-client/session/RESET_PASSWORD';
const FORGET = 'cube3-client/session/FORGET';
const REMEMBER = 'cube3-client/session/REMEMBER';
const VERIFY_AUTH = 'cube3-client/session/VERIFY_AUTH';
const SET_SHARE_PASSWORD = 'cube3-client/session/SET_SHARE_PASSWORD';
const CHANGE_EMAIL = 'cube3-client/session/CHANGE_EMAIL';
const CONFIRM_EMAIL = 'cube3-client/session/CONFIRM_EMAIL';
const ACTIVATE_MAGIC_LINK = 'cube3-client/session/ACTIVATE_MAGIC_LINK';
const MAGIC_LINK_ACTIVATED = 'cube3-client/session/MAGIC_LINK_ACTIVATED';
const MAGIC_LINK_FAILED = 'cube3-client/session/MAGIC_LINK_FAILED';
const REQUEST_SHARE_ACCESS = 'cube3-client/session/REQUEST_SHARE_ACCESS';
// 2FA
const TWOFA_LOGIN = 'cube3-client/session/2FA_LOGIN';
const ENROLL_TOTP = 'cube3-client/session/ENROLL_TOTP';
const ENROLL_TOTP_FINISH = 'cube3-client/session/ENROLL_TOTP_FINISH';
const CLEAR_ENROLL_CREDS = 'cube3-client/session/CLEAR_ENROLL_CREDS';
const SET_ENROLL_CREDS = 'cube3-client/session/SET_ENROLL_CREDS';
const REGEN_RECOVERY_CODE = 'cube3-client/session/REGEN_RECOVERY_CODE';

export type MagicLinkSuccess = { startSession(): Promise<never>; redirect_uri };
export type MagicLinkFailure = { errors: string | string[] };

type ShareURLTypes = 'share-link' | 'review' | 'review-link' | 'file-request';

export type TokenScope = 'full' | `/${ShareURLTypes}/${string}`;
export interface Session {
  auth: boolean;
  user: string;
  remember: boolean;
  account: string;
  workspace: string;
  sharelink: any;
  magicLink: MagicLinkSuccess | MagicLinkFailure;
  tokenScope: TokenScope;
  twoFAToken: string;
  qrCode: string;
  recoveryCode: string;
}

export const actions = {
  // SIGNUP,
  // SIGNUP_SUCCESS,
  // SIGNUP_FAILED,
  LOGIN,
  TOKEN_LOGIN,
  REGISTER_ACCOUNT,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGIN_SSO,
  LOGIN_MAGIC_LINK,
  LOGOUT,
  RESET,
  SET_CURRENT_ACCOUNT,
  SET_CURRENT_WORKSPACE,
  SET_CURRENT_SHARELINK,
  REQUEST_TOKEN_RESET_PASSWORD,
  RESET_PASSWORD,
  FORGET,
  REMEMBER,
  VERIFY_AUTH,
  SET_SHARE_PASSWORD,
  CHANGE_EMAIL,
  CONFIRM_EMAIL,
  ACTIVATE_MAGIC_LINK,
  MAGIC_LINK_ACTIVATED,
  MAGIC_LINK_FAILED,
  REQUEST_SHARE_ACCESS,
  // 2FA
  TWOFA_LOGIN,
  ENROLL_TOTP,
  ENROLL_TOTP_FINISH,
  SET_ENROLL_CREDS,
  CLEAR_ENROLL_CREDS,
  REGEN_RECOVERY_CODE
};

// const signup = ({ form, ...values }) => ({
//   type: SIGNUP,
//   payload: values,
//   meta: {
//     apiClient: {}
//   }
// });

// const signupSuccess = ({ userId }) => ({
//   type: SIGNUP_SUCCESS,
//   payload: { userId }
// });

// const signupFailed = ({ errors }) => ({
//   type: SIGNUP_FAILED,
//   error: { ...errors }
// });

const login = ({ email, password, remember = false }) => ({
  type: LOGIN,
  payload: {
    username: email,
    password: password?.extract ? password.extract() : password,
    remember
  },
  meta: {
    apiClient: {
      username: email,
      password: password?.extract ? password.extract() : password,
      remember
    }
  }
});
//#region 2fa login
const twoFALogin = ({ token, totp_code = '', recovery_code = '' }) => ({
  type: TWOFA_LOGIN,
  payload: {
    token,
    totp_code,
    recovery_code
  },
  meta: {
    apiClient: {
      token,
      totp_code,
      recovery_code
    }
  }
});

const enrollTOTP = () => ({
  type: ENROLL_TOTP,
  payload: {},
  meta: {
    apiClient: {}
  }
});

const enrollTOTPFinish = (totp_code) => ({
  type: ENROLL_TOTP_FINISH,
  payload: {
    totp_code
  },
  meta: {
    apiClient: {
      totp_code
    }
  }
});

const regenRecoveryCode = (totp_code) => ({
  type: REGEN_RECOVERY_CODE,
  payload: {
    totp_code
  },
  meta: {
    apiClient: {
      totp_code
    }
  }
});

const setEnrollCredentials = ({ qrCode = '', recoveryCode = '' }) => ({
  type: SET_ENROLL_CREDS,
  payload: {
    qrCode,
    recoveryCode
  }
});

const clearEnrollCredentials = () => ({
  type: CLEAR_ENROLL_CREDS
});

const loginSSO = ({ token, userId, remember = false }) => {
  return {
    type: LOGIN_SSO,
    meta: {
      apiClient: {
        token,
        userId,
        remember
      }
    }
  };
};

const loginMagicLink = ({ email }) => {
  return {
    type: LOGIN_MAGIC_LINK,
    meta: {
      apiClient: {
        email
      }
    }
  };
};

const tokenLogin = ({ token, remember = false, reviewLink = false }) => ({
  type: TOKEN_LOGIN,
  payload: {
    token,
    remember,
    reviewLink
  },
  meta: {
    apiClient: {
      token,
      remember
    }
  }
});

const registerAccount = ({
  email = undefined,
  token,
  password,
  full_name = undefined
}) => {
  return {
    type: REGISTER_ACCOUNT,
    meta: {
      apiClient: {
        email,
        token,
        password,
        full_name
      }
    }
  };
};

interface LoginSuccess {
  userId?: string;
  remember?: boolean;
  sharelinkId?: string;
  tokenScope: TokenScope;
  twoFAToken?: string;
}

const loginSuccess = ({
  userId,
  remember,
  sharelinkId = undefined,
  tokenScope,
  twoFAToken
}: LoginSuccess) => ({
  type: LOGIN_SUCCESS,
  payload: { userId, remember, sharelinkId, tokenScope, twoFAToken }
});

const loginFailed = ({ errors }) => ({
  type: LOGIN_FAILED,
  error: { ...errors }
});

const logout = ({ allSessions = true }) => ({
  type: LOGOUT,
  meta: {
    apiClient: {}
  },
  payload: { allSessions: !allSessions }
});

const reset = ({ allSessions = true }) => ({
  type: RESET,
  meta: {
    apiClient: {}
  },
  payload: { allSessions: !allSessions }
});

const setCurrentAccount = ({ accountId, workspaceId }) => ({
  type: SET_CURRENT_ACCOUNT,
  payload: { accountId },
  meta: {
    apiClient: {
      workspaceId
    }
  }
});

const setCurrentWorkspace = deprecate(
  (workspaceId) => ({
    type: SET_CURRENT_WORKSPACE,
    payload: { workspaceId }
  }),
  'workspace is set via setCurrentAccount now'
);

const setCurrentSharelink = (sharelinkId) => ({
  type: SET_CURRENT_SHARELINK,
  payload: { sharelinkId }
});

const setSharePassword = (token, password) => ({
  type: SET_SHARE_PASSWORD,
  payload: { token, password },
  meta: {
    apiClient: {}
  }
});

const requestTokenResetPassword = ({ email }) => ({
  type: REQUEST_TOKEN_RESET_PASSWORD,
  payload: { email_address: email },
  meta: {
    apiClient: {}
  }
});

const resetPassword = ({ password, token }) => ({
  type: RESET_PASSWORD,
  payload: { password, reset_hash: token },
  meta: {
    apiClient: {}
  }
});

const changeEmail = ({ userId, password, email }) => ({
  type: CHANGE_EMAIL,
  payload: { password, email },
  meta: {
    apiClient: { userId }
  }
});

const confirmEmail = ({ token }) => ({
  type: CONFIRM_EMAIL,
  payload: { token },
  meta: {
    apiClient: {}
  }
});

const forget = () => ({
  type: FORGET,
  payload: true
});

const remember = () => ({
  type: REMEMBER,
  payload: true
});

const verifyAuth = () => ({
  type: VERIFY_AUTH,
  payload: true,
  meta: {
    apiClient: {}
  }
});

const activateMagicLink = ({ id, token }: { id: string; token: string }) => ({
  type: ACTIVATE_MAGIC_LINK,
  meta: {
    apiClient: { id, token }
  }
});

const magicLinkActivated = (res) => ({
  type: MAGIC_LINK_ACTIVATED,
  payload: res
});

const magicLinkFailed = ({ errors }) => ({
  type: MAGIC_LINK_FAILED,
  payload: { errors }
});

const requestShareAccess = ({ shareToken, email, message }) => ({
  type: REQUEST_SHARE_ACCESS,
  meta: {
    apiClient: { shareToken, email, message }
  }
});

export const actionCreators = {
  // signup,
  // signupSuccess,
  // signupFailed,
  login,
  tokenLogin,
  registerAccount,
  loginSuccess,
  loginFailed,
  loginSSO,
  loginMagicLink,
  logout,
  reset,
  setCurrentAccount,
  setCurrentWorkspace,
  setCurrentSharelink,
  setSharePassword,
  requestTokenResetPassword,
  resetPassword,
  forget,
  remember,
  verifyAuth,
  changeEmail,
  confirmEmail,
  activateMagicLink,
  magicLinkActivated,
  magicLinkFailed,
  requestShareAccess,
  // 2FA
  enrollTOTP,
  enrollTOTPFinish,
  twoFALogin,
  setEnrollCredentials,
  clearEnrollCredentials,
  regenRecoveryCode
};

const isAuthenticated = (state) => !!state.auth;
const getUserId = (state) => (isAuthenticated(state) ? state.user : undefined);

export const selectors = {
  isAuthenticated,
  getUserId
};

export const initialState: Session = {
  auth: undefined,
  user: undefined,
  remember: undefined,
  account: undefined,
  workspace: undefined,
  sharelink: undefined,
  magicLink: undefined,
  tokenScope: undefined,
  twoFAToken: undefined,
  qrCode: undefined,
  recoveryCode: undefined
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        auth: true,
        user: payload.userId,
        sharelink: payload.sharelinkId,
        remember: payload.remember,
        tokenScope: payload.tokenScope,
        twoFAToken: payload.twoFAToken
      };

    // case SIGNUP_SUCCESS:
    //   return state;

    case LOGOUT:
      return {
        ...initialState
      };

    case SET_CURRENT_ACCOUNT:
      return state.auth
        ? {
            ...state,
            account: payload.accountId,
            workspace: action.meta.apiClient.workspaceId
          }
        : {
            ...initialState
          };

    case SET_CURRENT_WORKSPACE:
      return state.auth
        ? {
            ...state,
            workspace: payload.workspaceId
          }
        : {
            ...initialState
          };

    case SET_CURRENT_SHARELINK:
      return {
        ...state,
        sharelink: payload.sharelinkId
      };

    case REMEMBER:
      return {
        ...state,
        remember: true
      };

    case FORGET:
      return {
        ...state,
        remember: false
      };

    case MAGIC_LINK_ACTIVATED:
      return {
        ...state,
        magicLink: action.payload
      };
    case MAGIC_LINK_FAILED:
      return {
        ...state,
        magicLink: action.payload
      };

    case SET_ENROLL_CREDS:
      return {
        ...state,
        qrCode: action.payload.qrCode || state.qrCode,
        recoveryCode: action.payload.recoveryCode || state.recoveryCode
      };
    case CLEAR_ENROLL_CREDS:
      return {
        ...state,
        qrCode: undefined,
        recoveryCode: undefined
      };
    default:
      return state;
  }
};

export default reducer;

export const tokenScopeFromPath = (path) => {
  return path
    ? (new URL(path).pathname.split('/').slice(0, 4).join('/') as TokenScope)
    : undefined;
};
