// #region imports
import React, { useCallback, useEffect, useState } from 'react';

// helpers
import { EditNewProjectMetadataModal } from '../../Projects/Modals/EditNewProjectMetadataModal';
import { EditMetadataFormSmart } from '../../ContentPane/DetailView/MetaData/EditMetadataFormSmart';

// Types
import { MetadataCategory } from '@cube3/common/model/schema/resources/metadata-category';

// HOCS
import { useModalActions } from '../../../layout/Modals/modalActions';
import { useMultiForm } from '../../MetaData/hooks/useMultiForm';
import { useMappedId__ALPHA } from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { useTypedSelector } from '@cube3/state/src/redux/components/Hooks/useTypedSelector';
import { getErrorText } from '../../Projects/Modals/AddProjectModal';
import Cube3Button from '@cube3/ui/src/Buttons/Cube3Button';
import { generatePath, useHistory } from 'react-router-dom';
import { urlStructureProject } from '../../../routing/routingPaths';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import {
  iconArrowDown,
  iconChevronRight
} from '@cube3/ui/src/icons/GeneralSVG';
import { getFormError } from 'redux-form';

interface ModalProps {
  modalContext?: {
    createProject: (e: React.SyntheticEvent) => void;
    filterValues: any;
    tempId: string;
    retrievedForms: MetadataCategory[];
    hasRequiredFields: boolean;
  };
}

function EditNewProjectMetadata(props: ModalProps): JSX.Element {
  const {
    createProject,
    filterValues = {},
    tempId = '',
    retrievedForms,
    hasRequiredFields
  } = props.modalContext;

  const [workspaceId] = useCurrentWorkspace();

  const modalActions = useModalActions();
  const history = useHistory();
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [showAllFields, setShowAllFields] = useState(false);
  const [createProjectLoading, setCreateProjectLoading] = useState(undefined);

  const errors = useTypedSelector((state) =>
    getFormError('add_project')(state)
  );

  const mappedId = useMappedId__ALPHA(tempId);
  const metadataFor = React.useRef({
    type: 'project',
    id: undefined
  });

  const {
    allFormsValid,
    submitAllForms,
    allFormsSucceeded,
    formSubmitting,
    destroyForms
  } = useMultiForm(retrievedForms, true);

  const errorMsg = !mappedId ? errors && getErrorText(errors) : undefined;

  const handleClickCreate = useCallback(
    (e) => {
      // create new project
      if (!mappedId) {
        createProject(e);
      }
      setCreateProjectLoading(true);
      setShouldSubmit(true);
    },
    [createProject, mappedId]
  );

  useEffect(() => {
    if (mappedId) {
      setCreateProjectLoading(false);
    }
    if (!metadataFor.current.id && mappedId) {
      metadataFor.current.id = mappedId;
    }
    setCreateProjectLoading(undefined);
  }, [mappedId]);

  // after created new project, submit metadata values
  useEffect(() => {
    if (shouldSubmit && mappedId) {
      submitAllForms();
      setShouldSubmit(false);
    }
  }, [mappedId, shouldSubmit, submitAllForms, setShouldSubmit]);

  const handleClose = useCallback(() => {
    if (!formSubmitting) {
      modalActions.closeAllModals();
      destroyForms();
    }
  }, [formSubmitting, destroyForms, modalActions.closeAllModals]);

  return (
    <EditNewProjectMetadataModal
      title={`Enter ${hasRequiredFields ? 'required' : ''} project metadata`}
      onCloseEvent={handleClose}
      hasRequiredFields={hasRequiredFields}
      hasPrefilledFields={Object.keys(filterValues).length > 0}
      onClickPrevious={modalActions.previousModal}
      loading={!!createProjectLoading || formSubmitting}
      retrievedForms={retrievedForms}
      allFormsValid={allFormsValid}
      allFormsSucceeded={allFormsSucceeded && !formSubmitting}
      navigateTo={() =>
        mappedId &&
        history.push(
          generatePath(urlStructureProject, {
            workspaceId,
            projectId: mappedId
          })
        )
      }
      onClickCreate={handleClickCreate}
      renderMetaDataForm={(form) => (
        <EditMetadataFormSmart
          metadataFormId={form.id}
          metadataForResource={metadataFor.current}
          metadataForm={form}
          requiredFieldsOnly={!showAllFields}
          prefilledValues={filterValues}
          useDefault={true}
        />
      )}
      error={errorMsg}
    >
      <div style={{ paddingBottom: 20 }}>
        <Cube3Button
          colorVariant="ghost1"
          text={
            !showAllFields
              ? 'Show all project metadata fields'
              : 'Hide fields that are not required'
          }
          pathRightSvg={!showAllFields ? iconChevronRight : iconArrowDown}
          extraSVGProps={
            !showAllFields && { svgProps: { viewBox: '0 0 32 32' } }
          }
          onClick={() => setShowAllFields(!showAllFields)}
        />
      </div>
    </EditNewProjectMetadataModal>
  );
}

export default EditNewProjectMetadata;
