/* method to get the styles of the container element  relevant to fitting text */
export const getStyles = (element: HTMLElement) => {
  const style = window.getComputedStyle(element, null);

  const font = [
    style['font-weight'],
    style['font-style'],
    style['font-size'],
    style['font-family']
  ].join(' ');
  const padding =
    parseInt(style['padding-right'].replace(/\D/g, ''), 10) +
    parseInt(style['padding-left'].replace(/\D/g, ''), 10);

  const letterSpacing = style['letter-spacing'];

  return { font, padding, letterSpacing };
};
