import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  isValid,
  destroy,
  submit,
  hasSubmitSucceeded,
  isSubmitting,
  isPristine,
  getFormSyncErrors,
  isDirty,
  reset,
  clearSubmit
} from 'redux-form';
import {
  MetadataCategory,
  MetadataForm
} from '@cube3/common/model/schema/resources/metadata-category';
import { ContractKeyGroup } from '@cube3/common/model/schema/resources/contract-key-group';

export const useMultiForm = (
  categories: Array<ContractKeyGroup | MetadataCategory | MetadataForm> = [],
  newCreate = false
) => {
  const dispatch = useDispatch();
  const allFormsValid = useSelector((state) => {
    return categories.reduce(
      (acc, form) => {
        const valid = isValid(form.id)(state);
        return {
          ...acc,
          [form.id]: valid,
          allValid: !valid ? false : acc.allValid
        };
      },
      { allValid: true }
    ).allValid;
  });

  const { allSucceeded: allFormsSucceeded, anySucceeded: anyFormsSucceeded } =
    useSelector((state) => {
      return categories.reduce(
        (acc, form) => {
          const success = hasSubmitSucceeded(form.id)(state);
          const dirty = isDirty(form.id)(state);

          return {
            ...acc,
            [form.id]: success,
            anySucceeded: acc.anySucceeded ? true : success,
            allSucceeded: (success || !dirty) && acc.allSucceeded
          };
        },
        { allSucceeded: true, anySucceeded: false }
      );
    });

  const formSubmitting = useSelector((state) => {
    return categories.reduce(
      (acc, form) => {
        const submitting = isSubmitting(form.id)(state);
        return {
          ...acc,
          [form.id]: submitting,
          anySubmitting: acc.anySubmitting ? true : submitting
        };
      },
      { anySubmitting: false }
    ).anySubmitting;
  });

  const formsPristine = useSelector((state) => {
    return categories.reduce(
      (acc, form) => {
        const pristine = isPristine(form.id)(state);

        return {
          ...acc,
          [form.id]: pristine,
          allPristine: !pristine ? false : acc.allPristine
        };
      },
      { allPristine: true }
    );
  });

  const allFormsPristine = formsPristine.allPristine;

  const submitAllForms = useCallback(() => {
    if (allFormsValid) {
      return categories.forEach((f) => {
        if (!formsPristine[f.id] || newCreate) {
          dispatch(submit(f.id));
        }
      });
    }
  }, [allFormsValid, categories, formsPristine, newCreate, dispatch]);

  const allFormErrors = useSelector((state) => {
    return categories.reduce(
      (acc, form) => {
        const errors = getFormSyncErrors(form.id)(state);
        return {
          ...acc,
          [form.id]: errors,
          hasErrors: Object.keys(errors).length ? true : acc.hasErrors
        };
      },
      { hasErrors: false }
    );
  });

  /** Maps over the retrieved forms and dispatches a destroy event per form */
  const destroyForms = () => {
    requestAnimationFrame(() => {
      categories.forEach((f) => dispatch(reset(f.id)));
      dispatch(destroy(...categories.map((f) => f.id)));
      categories.forEach((f) => dispatch(clearSubmit(f.id)));
    });
  };

  return {
    allFormsValid,
    submitAllForms,
    allFormsSucceeded: allFormsSucceeded && anyFormsSucceeded,
    formSubmitting,
    allFormsPristine,
    allFormErrors,
    destroyForms
  };
};
