import axios, { AxiosRequestConfig, AxiosAdapter } from 'axios';
import Cookies from 'js-cookie';
import { environment } from '../utils/environment';
import { BrowserStorage } from './uploader/browserStorage';

const defaults = {
  authStorage: 'sessionStorage'
};

interface AuthServiceConfig extends AxiosRequestConfig {
  apiRoot: string;
  endpoints: {
    login: string;
    logout: string;
    register: string;
    legacyToken: string;
    activeSessions: string;
    magicLink: string;
    enrollTotp: string;
    enrollTotpFinish: string;
    twoFALogin: string;
    generateRecoveryCode: string;
    reGenerateRecoveryCode: string;
  };
  workspaceId?: string;
  shareId?: string;
  adapter?: AxiosAdapter;
}

export interface LoginResponse {
  data: {
    token_type: string;
    access_token: string;
    expires_at: string;
    id_token: string;
    refresh_token: string;
    share?: { uuid: string };
    /** An exchange token will be used in a subsequent call to enter their OTP code. */
    token?: string;
  };
}

export interface EnrollTOTPResponse {
  data: File;
}

export interface RegisterResponse {
  data: {};
}

export interface LegacyTokenResponse {
  data: {
    token_type: string;
    access_token: string;
  };
}

export interface PasswordCredentials {
  username: string;
  password: string;
}

export interface TokenCredentials {
  token: string;
}

export type LoginCredentials = PasswordCredentials | TokenCredentials;

export interface LegacyTokens {
  userHash: string;
  session_token: string;
}

const FAKE_DELAY = 450;

export class AuthService {
  config;
  axios;
  ioclientUrl;
  private storage;
  private storageLocked;

  noAuthHandlers = {
    noAuth: () => console.warn('no auth callback not defined'),
    noAccess: () => console.warn('no access callback not defined'),
    noBeta: () => console.warn('no beta access callback not defined')
  };

  interceptors = {
    requestInterceptor: (req) => this.requestInterceptor(req),
    requestErrorInterceptor: (error) => this.requestErrorInterceptor(error),
    responseInterceptor: (res) => this.responseInterceptor(res),
    responseErrorInterceptor: (error) => this.responseErrorInterceptor(error)
  };

  constructor(config: AuthServiceConfig) {
    if (
      !config.apiRoot ||
      !config.endpoints ||
      !config.endpoints.login ||
      !config.endpoints.register ||
      !config.endpoints.legacyToken ||
      !config.endpoints.activeSessions ||
      !config.endpoints.magicLink ||
      !config.endpoints.enrollTotp ||
      !config.endpoints.enrollTotpFinish ||
      !config.endpoints.generateRecoveryCode ||
      !config.endpoints.reGenerateRecoveryCode ||
      !config.endpoints.twoFALogin
    ) {
      throw new Error('Endpoint was misconfigured');
    }

    this.config = { ...defaults, ...config };

    this.storage = BrowserStorage.getInstance('auth');

    this.axios = axios.create({
      baseURL: config.apiRoot,
      adapter: config.adapter
    });
  }

  setIoclientUrl = (url) => {
    if (environment === 'production' && new URL(url).protocol !== 'https:') {
      throw new Error(`${url} not allowed. Must use https.`);
    }
    this.ioclientUrl = url;
  };

  login = (credentials: LoginCredentials) => {
    if (Object.hasOwn(credentials, 'username')) {
      return this.loginWithPassword(credentials as PasswordCredentials);
    }
  };

  loginWithPasswordRequest = ({ username, password }: PasswordCredentials) => {
    return this.axios.post(this.config.endpoints.login, { username, password });
  };

  loginWithPassword = ({ username, password }: PasswordCredentials) => {
    this.clearStores();
    return this.loginWithPasswordRequest({ username, password }).then(
      (res: LoginResponse) => {
        this.store(res.data);
        return res;
      }
    );
  };

  //#region 2FA login
  /** enroll totp */
  enrollTotp = () => {
    return this.getAxios().request({
      method: 'POST',
      url: this.config.endpoints.enrollTotp,
      responseType: 'arraybuffer'
    });
  };
  /** finish enrolling totp */
  enrollTotpFinish = (totp_code) => {
    return this.getAxios().request({
      method: 'POST',
      url: this.config.endpoints.enrollTotpFinish,
      data: {
        totp_code
      }
    });
  };

  twoFALogin = ({ token, totp_code, recovery_code }) => {
    this.clearStores();
    return this.getAxios()
      .request({
        method: 'POST',
        url: this.config.endpoints.twoFALogin,
        data: totp_code
          ? {
              token,
              totp_code
            }
          : {
              token,
              recovery_code
            }
      })
      .then((res) => {
        this.store(res.data);
        return res;
      });
  };

  generateRecoveryCode = (totp_code, regen = false) => {
    return this.getAxios().request({
      method: 'POST',
      url: regen
        ? this.config.endpoints.reGenerateRecoveryCode
        : this.config.endpoints.generateRecoveryCode,
      data: { totp_code }
    });
  };

  // single sign on
  sso = (token) => {
    this.clearStores();
    this.store({ access_token: token });
  };

  // TODO move into separate module, for reuse?
  shouldBeSecure = () => {
    return environment !== 'development' && environment !== 'test';
  };

  registerAccount = ({
    email,
    token,
    password = undefined,
    full_name = undefined
  }) => {
    return this.axios.post(this.config.endpoints.register, {
      data: {
        attributes: {
          email,
          token,
          password,
          full_name
        }
      }
    });
  };

  logout = (revoke_all = true) => {
    this.lockStores();
    return this.getAxios().request({
      url: this.config.endpoints.logout,
      method: 'POST',
      data: { revoke_all }
    });
  };

  getActiveSessions = () => {
    const stored = this.retrieve();

    if (!stored) {
      return Promise.reject(new Error('NotLoggedIn'));
    }

    return this.getAxios()
      .request({
        url: this.config.endpoints.activeSessions,
        method: 'GET',
        headers: {
          Authorization: `Cube2 ${stored.access_token}`,
          'X-Cube3-WorkspaceID': this.config.workspaceId
        }
      })
      .then((res) => res.data.data);
  };
  //#region verify auth
  // verify that the token used to authenticate with is still valid
  verify = () => {
    const stored = this.retrieve();

    if (!stored) {
      return Promise.reject(new Error('NotLoggedIn'));
    }

    return this.getAxios()
      .request({
        url: this.config.endpoints.activeSessions,
        method: 'GET',
        headers: {
          Authorization: `Cube2 ${stored.access_token}`,
          'X-Cube3-WorkspaceID': this.config.workspaceId
        }
      })
      .then((res) => {
        if (res.data.data.find((s) => s.meta.is_current)) {
          return Promise.resolve(true);
        }
        return Promise.reject(new Error('NotLoggedIn'));
      });
  };

  getAccessToken = () => {
    const stored = this.retrieve();
    return stored?.access_token || undefined;
  };

  getLegacyToken = (): Promise<LegacyTokens> => {
    const stored = this.retrieve();

    if (!stored?.access_token || !this.config.workspaceId) {
      console.error("Can't exchange token when not logged in");
      return Promise.reject("Can't exchange token when not logged in");
    }

    return this.axios
      .get(this.config.endpoints.legacyToken, {
        headers: {
          Authorization: `Cube2 ${stored.access_token}`,
          'X-Cube3-WorkspaceID': this.config.workspaceId
        }
      })
      .then((res: LegacyTokenResponse) => {
        return {
          userHash: res.data.access_token,
          session_token: stored.access_token,
          workspaceId: this.config.workspaceId
        };
      });
  };

  callLegacyHandler = (method, params) => {
    const paramString = Object.keys(params)
      .map((k) => `${k}=${encodeURIComponent(params[k])}`)
      .join('&');

    return this.getLegacyToken().then((tokens) => {
      console.warn('calling legacy handler', this.ioclientUrl, method, params);
      return this.axios.get(
        `${
          this.ioclientUrl
        }/ioclient.php?method=${method}&resType=json&session_token=${encodeURIComponent(
          tokens.session_token
        )}&userHash=${encodeURIComponent(tokens.userHash)}&${paramString}`,
        {
          withCredentials: true
        }
      );
    });
  };

  setSharePassword = (password, shareToken) => {
    this.store({
      sharePassword: password,
      passwordFor: shareToken
    });
  };

  lockStores = () => {
    this.storageLocked = true;
  };

  unlockStores = () => {
    this.storageLocked = false;
  };

  clearStores = () => {
    this.storage.removeItem('tokens');
    Cookies.remove('download_token', {
      path: `${this.config.apiRoot}/files/`,
      secure: this.shouldBeSecure()
    });
    Cookies.remove('share_download_token', {
      path: `${this.config.apiRoot}/files/`,
      secure: this.shouldBeSecure()
    });
    Cookies.remove('share_download_password', {
      path: `${this.config.apiRoot}/files/`,
      secure: this.shouldBeSecure()
    });

    this.config.workspaceId = undefined;
    this.config.shareId = undefined;
    this.unlockStores();
  };

  store = (data) => {
    const oldData = this.storage.getItem('tokens') || {};
    const newData = { ...oldData, ...data };
    this.storage.setItem('tokens', newData);

    Cookies.set('download_token', newData.access_token, {
      path: `${this.config.apiRoot}/files/`,
      secure: this.shouldBeSecure(),
      sameSite: 'strict'
    });
  };

  retrieve = () => {
    const data = this.storage.getItem('tokens');

    return data;
  };

  requestInterceptor = (req) => {
    // during state RESET we want to stop sending auth headers
    // but still need the token for the final logout request
    // so we limit what requests can use the token, then clear
    // the storage afterwards
    let stored;

    if (!this.storageLocked || req.url.includes(this.config.endpoints.logout)) {
      stored = this.retrieve();
    }

    if (!stored && !this.config.shareId) {
      return req;
    }
    const token = stored && stored.access_token;

    if (req.headers.Authorization || token) {
      req.headers.Authorization = req.headers.Authorization || `Cube2 ${token}`;
      const cookieToken = Cookies.get('download_token');
      if (!cookieToken || cookieToken !== token) {
        Cookies.set('download_token', token, {
          path: `${this.config.apiRoot}/files/`,
          secure: this.shouldBeSecure(),
          sameSite: 'strict'
        });
      }
    } else {
      Cookies.remove('download_token');
    }

    if (this.config.shareId && !this.shouldExcludeShareId(req)) {
      req.headers['X-Cube3-ShareID'] = this.config.shareId;

      Cookies.set('share_download_token', this.config.shareId, {
        path: `${this.config.apiRoot}/files/`,
        secure: this.shouldBeSecure(),
        sameSite: 'strict'
      });

      if (stored?.sharePassword && stored.passwordFor === this.config.shareId) {
        req.headers['X-Cube3-SharePassword'] = stored.sharePassword;

        Cookies.set('share_download_password', stored.sharePassword, {
          path: `${this.config.apiRoot}/files/`,
          secure: this.shouldBeSecure(),
          sameSite: 'strict'
        });
      }
    } else {
      Cookies.remove('share_download_token', {
        path: `${this.config.apiRoot}/files/`,
        secure: this.shouldBeSecure()
      });
      Cookies.remove('share_download_password', {
        path: `${this.config.apiRoot}/files/`,
        secure: this.shouldBeSecure()
      });
    }

    if (this.config.workspaceId) {
      req.headers['X-Cube3-WorkspaceID'] = this.config.workspaceId;
    }

    return req;
  };

  shouldExcludeShareId = (req) => {
    return (
      req.url.includes('/users/') ||
      req.url.includes(this.config.endpoints.activeSessions) ||
      req.url.includes(this.config.endpoints.logout) ||
      req.url.includes('request-access')
    );
  };

  setIntegrationCookies = ({ workspaceId, path }) => {
    const stored = this.retrieve();
    const token = stored?.access_token;

    if (!token) {
      throw new Error('need to be signed in to set integration cookies');
    }

    Cookies.set('oauth2_flow_session', token, {
      path: path,
      secure: this.shouldBeSecure(),
      sameSite: 'strict',
      expires: 1 / 24 // 1hr
    });
    Cookies.set('oauth2_flow_workspace', workspaceId, {
      path: path,
      secure: this.shouldBeSecure(),
      sameSite: 'strict',
      expires: 1 / 24 // 1hr
    });
  };

  activePoll = undefined;
  cancelMagicLinkLogin = () => (this.activePoll = undefined);
  magicLinkLogin = (email: string) => {
    this.activePoll = undefined;
    return this.getAxios()
      .request({
        method: 'POST',
        url: this.config.endpoints.magicLink,
        data: { email }
      })
      .then((res) => {
        let attempt = 0;
        const poll = () => {
          attempt += 1;

          if (attempt > 90) {
            return Promise.reject(new Error('Magic link timed out'));
          }

          return this.getAxios()
            .request({
              method: 'GET',
              url: `${this.config.endpoints.magicLink}/${res.data.id_token}`
            })

            .then((res) => {
              if (res.status >= 400) {
                if (this.activePoll !== poll) {
                  return;
                }
                return new Promise((res) =>
                  setTimeout(() => res(poll()), 2000)
                );
              } else {
                this.sso(res.data.access_token);
                return res;
              }
            })
            .catch((e) => {
              if (this.activePoll !== poll) {
                return;
              }
              return new Promise((res) => setTimeout(() => res(poll()), 3000));
            });
        };
        this.activePoll = poll;
        return new Promise((res) => setTimeout(() => res(poll()), 5000));
      });
  };

  magicLinkActivate = ({ id, token }) => {
    return new Promise((res) => setTimeout(res, FAKE_DELAY)).then(() =>
      this.getAxios().request({
        method: 'GET',
        url: `${this.config.endpoints.magicLink}/${id}/?token=${token}`
      })
    );
  };

  requestShareAcccess = ({ shareToken, email, message }) => {
    return new Promise((res) => setTimeout(res, FAKE_DELAY)).then(() =>
      this.getAxios().request({
        method: 'POST',
        url: `auth/request-access`,
        data: {
          data: {
            attributes: {
              email,
              message,
              shareToken
            }
          }
        }
      })
    );
  };

  approveShareAccess = ({ shareId, token }) => {
    return this.getAxios()
      .request({
        method: 'GET',
        url: `shares/${shareId}/approve-access/${token}`
      })
      .catch((e) => {
        throw new Error(
          e.response?.data?.errors?.[0].code || 'Failed to grant access'
        );
      });
  };

  handleAuthErrors(config) {
    this.noAuthHandlers = { ...this.noAuthHandlers, ...config };
  }

  requestErrorInterceptor = (error) => Promise.reject(error);
  responseInterceptor = (res) => res;
  responseErrorInterceptor = (error) => {
    if (error.code === 'ERR_CANCELED') {
      return Promise.reject('ERR_CANCELED');
    }

    if (error.response.status === 401) {
      this.noAuthHandlers.noAuth();
    } else if (error.response.status === 403) {
      if (error.response.data.error === 'NO_CUBE3_BETA_ACCESS') {
        this.noAuthHandlers.noBeta();
      } else {
        this.noAuthHandlers.noAccess();
      }
    } else {
      console.warn('unhandled api error', { error });
    }

    return Promise.reject(error);
  };

  getAxios = () => {
    const a = axios.create({
      baseURL: this.config.apiRoot
    });

    a.interceptors.request.use(
      this.interceptors.requestInterceptor.bind(a),
      this.interceptors.requestErrorInterceptor.bind(a)
    );
    a.interceptors.response.use(
      this.interceptors.responseInterceptor.bind(a),
      this.interceptors.responseErrorInterceptor.bind(a)
    );
    return a;
  };
}
